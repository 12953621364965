@import '../../goblalStyles/settings';
@import '../../goblalStyles/content-container';
@import '../../goblalStyles/button';

.pageHeader {
  background: #f7f7f7;
  padding: $l-size 0;
  margin-bottom: $l-size;

}
.pageHeader__actions {
  margin-top: $m-size;
}
.pageHeader__title {
  font-weight: 300;
  margin: 0;
  span {
    font-weight: 700;
  }
}
