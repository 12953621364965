.loader{
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.loaderImg{
  height: 6rem;
  width: 6rem;
}