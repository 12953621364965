@import '../../goblalStyles/settings';

.textInput{
  border: 1px solid #cacccc;
  height: 50px;
  padding: $s-size;
  font-size: $font-size-large;
  font-weight: 300;
  border-radius: 6px;
}

.select{
  @extend .textInput;
}

.textarea{
  @extend .textInput;
  height: 10rem;
}