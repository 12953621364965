@import '../../goblalStyles/content-container';
@import '../../goblalStyles/settings';
@import '../../goblalStyles/input';

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: $s-size;
  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
    margin-bottom: $l-size;
  }
}

.inputGroup__item {
  margin-right: $s-size;
  margin-bottom: $s-size;
  @media (min-width: $desktop-breakpoint) {
    margin-bottom: 0 $s-size 0 0;
  }
}
